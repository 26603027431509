import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Button, IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { Amplify, Auth } from "aws-amplify";

import { SnackBar } from "./commons/alert";
import axiosClient from "./commons/axiosClient";
import GenericBackdrop from "./commons/GenericBackdrop";
import OtpMail from "./otpMail";

const Signin = () => {
  const [t] = useTranslation("global");
  const PREFIX_COGNITO = "COG";

  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [tmpEmail, setTmpEmail] = useState("");

  const [pass, setPass] = useState("");
  const [tmpPass, setTmpPass] = useState({
    password: "",
    showPassword: false,
  });

  const [showOtp, setShowOtp] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);

  const registerUser = async () => {
    Amplify.configure({
      Auth: {
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        authenticationFlowType: process.env.REACT_APP_COGNITO_USER_FLOW_TYPE,
      },
      clientMetadata: {
        Name: email,
        Country: process.env.REACT_APP_COUNTRY,
        password: pass,
        device_type: "EMAIL",
        authentication_type: "PASSWORD",
        Environment: process.env.REACT_APP_ENVIRONMENT_COGNITO,
      },
    });
    try {
      await execSingInAuth();
      await loginUser();
    } catch (_) {
      handleLoaderClose();
      SnackBar(t("General.Message.ErrorService"), "error");
    }
  };

  const execSingInAuth = async () => {
    let responseCognito = await Auth.signIn(email, PREFIX_COGNITO + pass);

    setUser(responseCognito);
    setShowOtp(true);
    setTmpEmail("");
    setTmpPass({
      password: "",
      showPassword: false,
    });

    handleLoaderClose();
  };

  const loginUser = async () => {
    Amplify.configure({
      Auth: {
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        authenticationFlowType: process.env.REACT_APP_COGNITO_CUSTOM_FLOW_TYPE,
      },
    });

    try {
      await execSingInAuth();
    } catch (error) {
      if (error?.code === "UserNotFoundException") {
        await registerUser();
      } else if (pass.length === 4) {
        axiosClient
          .post(
            "pre-login-cognito",
            JSON.stringify({
              user: email,
              password: pass,
            })
          )
          .then(async () => {
            await execSingInAuth();
          })
          .catch((_) => {
            handleLoaderClose();
            SnackBar(t("General.Message.ErrorService"), "error");
          });
      } else {
        handleLoaderClose();
        SnackBar(t("General.Message.ErrorService"), "error");
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    handleLoaderOpen();
    await loginUser();
  };

  const handleChange = (prop) => (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    if (name === "email") {
      setEmail(value.trim());
      setTmpEmail(value.trim());
    }

    if (name === "pass") {
      setPass(value);
      setTmpPass({ ...tmpPass, [prop]: value });
    }
  };

  const handleClickShowPassword = (event) => {
    event.preventDefault();

    setTmpPass({
      ...tmpPass,
      showPassword: !tmpPass.showPassword,
    });
  };

  const handleForgot = (event) => {
    event.preventDefault();
    const PARENT_URL = document.location.ancestorOrigins[0];

    handleLoaderOpen();

    window.parent.postMessage({ message: "FORGOT", data: { email: email }, code: 200 }, PARENT_URL);

    handleLoaderClose();
  };

  return (
    <Fragment>
      {!showOtp && (
        <Stack
          direction="column"
          sx={{
            backgroundColor: "#fff",
            borderRadius: "16px",
            fontFamily: "Readex Pro",
          }}
          padding={1}
        >
          <Stack justifyContent="center" alignItems="center" mt={4} mb={4}>
            <h2
              style={{
                fontFamily: "Readex Pro",
                fontSize: "28px",
                fontWeight: "600",
                lineHeight: "34px",
                letterSpacing: "0px",
                color: "#343C46",
                textAlign: "center",
              }}
            >
              {t("Login.Title")}
            </h2>
          </Stack>
          <form
            onSubmit={async (event) => {
              await handleSubmit(event);
            }}
          >
            <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" sx={{ width: "100%" }}>
              <Stack sx={{ width: "100%" }}>
                <TextField
                  required
                  autoComplete="username"
                  id="outlined-number"
                  name="email"
                  value={tmpEmail}
                  label={t("Login.Email")}
                  onChange={handleChange()}
                  inputProps={{
                    inputMode: "email",
                    pattern: "^[^@]+@[^@]+.[a-zA-Z]{2,}$",
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: "16px",
                      fontSize: "Readex Pro",
                      width: "100%",
                    },
                  }}
                />
              </Stack>
            </Stack>
            <Stack sx={{ width: "100%", marginTop: "40px" }}>
              <TextField
                required
                label={t("Login.Dual")}
                name="pass"
                variant="outlined"
                type={tmpPass.showPassword ? "text" : "password"}
                onChange={handleChange("password")}
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton data-testid="ShowPass" onClick={handleClickShowPassword}>
                        {tmpPass.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: {
                    borderRadius: "16px",
                    fontSize: "Readex Pro",
                    width: "100%",
                  },
                }}
              />
            </Stack>
            <Stack sx={{ width: "100%" }}>
              <Button
                sx={{
                  fontFamily: "Readex Pro",
                  textDecoration: "none",
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#404A56",
                }}
                onClick={(e) => handleForgot(e)}
                data-testid="btn-forgot"
              >
                {t("Login.ForgotPassword")}
              </Button>
            </Stack>
            <Stack mt={4} mb={4} justifyContent="center" alignItems="center">
              <Button
                variant="contained"
                type="submit"
                data-testid="btn-login"
                sx={{
                  width: "100%",
                  height: "48px",
                  background: "#363853",
                  color: "white",
                  fontFamily: "Readex Pro",
                  margin: "10px 0px 20px 0px",
                  borderRadius: "16px",
                }}
              >
                {t("Login.Enter")}
              </Button>
            </Stack>
          </form>
        </Stack>
      )}
      {showOtp && (
        <OtpMail
          showOtp={showOtp}
          setShowOtp={setShowOtp}
          user={user}
          setUser={setUser}
          credentials={{
            email: email,
            password: PREFIX_COGNITO + pass,
          }}
        />
      )}
      <GenericBackdrop open={loader} />
    </Fragment>
  );
};

export default Signin;
