import Swal from "sweetalert2";

export function Alert(title, text, icon) {
  return Swal.fire({
    title: title,
    text: text,
    icon: icon,
    confirmButtonText: "OK",
    customClass: {
      confirmButton: "swal-button-contained",
      title: "swal-title",
      htmlContainer: "swal-htmlContainer",
    },
  });
}

export async function Confirm(title, text, icon) {
  const result = await Swal.fire({
    title: title,
    html: text,
    icon: icon,
    showCancelButton: true,
    confirmButtonText: "OK",
    customClass: {
      confirmButton: "swal-button-contained",
      cancelButton: "swal-button-outlined",
      title: "swal-title",
      htmlContainer: "swal-htmlContainer",
    },
    reverseButtons: true,
  });

  return result.isConfirmed;
}

export const SnackBar = (message, icon) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    showConfirmButton: false,
    timer: 2500,
    background: "#00C8FF",
    color: "#fff",
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: icon,
    title: message,
  });
};
