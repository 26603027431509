import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { Provider } from "react-redux";

import { ThemeProvider } from "@mui/material/styles";
import i18next from "i18next";

import App from "./App";
import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import Routing from "./Routing";
import Theme from "./themes/Theme";
import global_en from "./translations/en/global.json";
import global_es from "./translations/es/global.json";

const rootElement = document.getElementById("root") || document.createElement("div");
const root = createRoot(rootElement);

i18next.use(initReactI18next).init({
  lng: "es",
  resources: {
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
  },
});

root.render(
  <StrictMode>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <ThemeProvider theme={Theme}>
          <Routing>
            <App />
          </Routing>
        </ThemeProvider>
      </Provider>
    </I18nextProvider>
  </StrictMode>
);

reportWebVitals();
