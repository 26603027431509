import React from "react";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function GenericBackdrop(props) {
  return (
    <Backdrop open={props.open}>
      <CircularProgress data-testid="loader" color="inherit" />
    </Backdrop>
  );
}

export default GenericBackdrop;
