import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";

import axiosClient from "../commons/axiosClient";

const LinkAccount = (props) => {
  const [t] = useTranslation("global");
  const [isError, setIsError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [urlRedirect, setUrlRedirect] = useState(null);
  const [messages, setMessage] = useState(null);

  useEffect(() => {
    const linkDevice = () => {
      setIsError(false);
      setSuccess(false);
      setLoading(true);

      Object.assign(axiosClient.defaults.headers, {
        Authorization: props.token,
      });

      axiosClient
        .post("callback", props.userData)
        .then((response) => {
          setSuccess(true);
          setLoading(false);

          const { url, message } = response.data;
          setUrlRedirect(url);
          setMessage(message);
        })
        .catch((_) => {
          setIsError(true);
          setLoading(false);
        });
    };

    linkDevice();
  }, [props]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (urlRedirect != null) window.location.href = urlRedirect;
    }, 1500);

    return () => clearTimeout(timer);
  }, [urlRedirect]);

  return (
    <Box data-testid="linkAccount">
      {loading && (
        <Box sx={{ textAlign: "center", m: 1 }}>
          <CircularProgress />
        </Box>
      )}
      {success && (
        <Fragment>
          <Box
            sx={{
              textAlign: "center",
              m: 2,
              color: "#00377D",
              fontSize: "h5.fontSize",
            }}
          >
            <Typography variant="text24" gutterBottom>
              {messages}
            </Typography>
          </Box>
        </Fragment>
      )}
      {isError && <Box sx={{ textAlign: "center", m: 1, color: "#00377D" }}>{t("Message.LinkAccount.Error")}</Box>}
    </Box>
  );
};

LinkAccount.propTypes = {
  userData: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
};

export default LinkAccount;
