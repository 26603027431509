import axios from "axios";

const axiosMerchant = axios.create();

axiosMerchant.defaults.baseURL = process.env.REACT_APP_MERCHANT_BACK;

axiosMerchant.defaults.headers = {
  "Content-Type": "application/json",
  "x-api-key": process.env.REACT_APP_MERCHANT_API_KEY,
  Accept: "application/json",
};

axiosMerchant.defaults.timeout = 30000;

export default axiosMerchant;
