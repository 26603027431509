import React from "react";
import { Routes } from "react-router-dom";

import "./App.css";

function App() {
  return <Routes>...</Routes>;
}

export default App;
